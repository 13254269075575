define('m0601/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({

    version: "##version##",

    wuerth: false,
    swg: false,
    reisser: true,

    loggedIn: true,

    environment: "m0601reisserdev", // "m0601reisser" = reisser

    initialized: false,
    calculationid: "",
    debug: true,
    xml: false,

    projektdatenInvalid: false,
    systemInvalid: true,
    auflagerInvalid: false,
    konstruktionInvalid: false,
    materialienInvalid: true,
    einwirkungInvalid: false,
    schwingungsnachweisInvalid: false,
    verbindungsmittelInvalid: true,
    optionenInvalid: false,

    schraubenvorauswahlDisabled: true,
    lastInBearbeitung: false,
    tab: "tab",

    server: "",
    pfad: "",

    username: "",
    sessionid: "",

    cC: "DE",

    si: "DE",
    so: "DE",
    kl: "DE",
    kat: "DE",
    lc: "EC 1 NAD (DE)",
    ln: "DE",
    tc: "EC 5 NAD (DE)",
    tn: "DE",

    languages: {
      "DE": "deutsch",
      "DK": "dansk",
      "EN": "englisch",
      "FR": "franzoesisch",
      "IT": "italienisch",
      "NL": "nederlands",
      "PL": "polnisch",
      "ES": "espanol",
      "FI": "suomi",
      "SE": "schwedisch"
    },

    countries: {
      "CL": "chile",
      "DK": "daenemark",
      "DE": "deutschland",
      "EN": "england",
      "FI": "finnland",
      "FR": "frankreich",
      "NL": "niederlande",
      "IT": "italien",
      "LU": "luxemburg",
      "HR": "kroatien",
      "NO": "norwegen",
      "AT": "oesterreich",
      "PL": "polen",
      "RO": "rumaenien",
      "SE": "schweden",
      "CH": "schweiz",
      "SK": "slowakei",
      "SI": "slowenien",
      "ES": "spanien",
      "CZ": "tschechien",
      "HU": "ungarn"
    },

    selectedLanguage: "deutsch",
    selectedCountry: "deutschland",

    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "http://www.holzbauverbinder.de/produktinfo/wuerth/katalog/katalog_de.pdf",
    eta: "http://www.holzbauverbinder.de/produktinfo/wuerth/eta/eta-110190_de.pdf",
    unsaved: true,

    i18n: inject.service(),
    current: computed.readOnly('i18n.locale'),

    bemessungsvorschrift: "EC5 (DE)",

    init: function init() {
      var self = this;
      this._super();

      var username = self.getParameterByName('user_name');
      var sessionid = self.getParameterByName('user_sessionid');

      self.setCountryVars();

      self.set('username', username);
      self.set('sessionid', sessionid);

      var environment = externalConfig.environments[self.get('environment')];
      var url = externalConfig[environment].uriAuthentifizierung;

      $.ajax({
        type: "POST",
        //url: "http://127.0.0.1:8000/Nutzerverwaltung/authentifiziereSession/",
        url: url,
        data: {
          username: username,
          sessionid: sessionid
        }
      }).done(function (res) {
        var authenticationResult = res.split(";");
        var auth = parseInt(authenticationResult[0]);
        var level = parseInt(authenticationResult[1]);
        if (auth == -1) {
          console.log("login unsuccessful.");
        } else if (auth == 0) {
          self.set('loggedIn', true);
          if (level == 0) {
            self.set('bauderMaster', false);
          } else if (level >= 1) {
            self.set('bauderMaster', true);
          }
          var applicationdata = self.get('model').application.objectAt(0);
          applicationdata.set('userName', username);

          // applicationdata.set('spracheinput', self.get('si'));
          // applicationdata.set('spracheoutput', self.get('so'));
          // applicationdata.set('kennungland', self.get('kl'));
          // applicationdata.set('katalog', self.get('kat'));
          // applicationdata.set('loadsCode', self.get('lc'));
          // applicationdata.set('loadsNA', self.get('ln'));
          // applicationdata.set('timberCode', self.get('tc'));
          // applicationdata.set('timberNA', self.get('tn'));

          console.log("login successful!");
        }

        // self.controllerFor('supercontroller').logaufruf("initialisiereGUI", 200);

        var applicationdata = self.get('model').application.objectAt(0);

        applicationdata.set('spracheinput', self.get('si'));
        self.send('setSpracheInput', self.get('si'));

        applicationdata.set('spracheoutput', self.get('so'));
        applicationdata.set('kennungland', self.get('kl'));
        applicationdata.set('katalog', self.get('kat'));
        applicationdata.set('loadsCode', self.get('lc'));
        applicationdata.set('loadsNA', self.get('ln'));
        applicationdata.set('timberCode', self.get('tc'));
        applicationdata.set('timberNA', self.get('tn'));

        console.log("kl: " + self.get('kl') + " ::: " + applicationdata.get('kennungland'));

        self.set('selectedCountry', self.get('i18n').t(self.countries[self.get('kl')]));

        var server = externalConfig[environment].server;
        var pfad = externalConfig[environment].pfad;

        // $.ajax({
        //   type: "POST",
        //   url: server + "m0601/DE/initialisiereGUI/" +self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
        //   data: {
        //
        //   }
        // }).done(function(data, statusText, xhr) {

        self.controllerFor('supercontroller').logaufruf("initialisiereGUI", 200);

        //   console.log('initialisiereGUI data: ');
        //   console.log(data);
        //
        // })
      });

      self.store.createRecord('bauteile', {});
      self.store.createRecord('lasteinwirkung', {});
      self.store.createRecord('anschlussgeometrie', {});
      self.store.createRecord('projektdaten', {});

      self.transitionToRoute('projektdaten');

      // var environment = externalConfig.environments.m13;

      // console.log('self.environment');
      // console.log(self.environment);
      // console.log('externalConfig');
      // console.log(externalConfig);

      var environment = externalConfig.environments[self.environment];

      console.log('self.environment: ' + self.environment);

      self.set('server', externalConfig[environment].server);
      self.set('pfad', externalConfig[environment].pfad);

      console.log('server: ' + self.get('server') + ' ::: pfad: ' + self.get('pfad'));

      self.get('i18n').addTranslations('cz', externalTranslations.cz);
      self.get('i18n').addTranslations('de', externalTranslations.de);
      self.get('i18n').addTranslations('dk', externalTranslations.dk);
      self.get('i18n').addTranslations('ee', externalTranslations.ee);
      self.get('i18n').addTranslations('en', externalTranslations.en);
      self.get('i18n').addTranslations('es', externalTranslations.es);
      self.get('i18n').addTranslations('fi', externalTranslations.fi);
      self.get('i18n').addTranslations('fr', externalTranslations.fr);
      self.get('i18n').addTranslations('gr', externalTranslations.gr);
      self.get('i18n').addTranslations('hr', externalTranslations.hr);
      self.get('i18n').addTranslations('hu', externalTranslations.hu);
      self.get('i18n').addTranslations('it', externalTranslations.it);
      self.get('i18n').addTranslations('lt', externalTranslations.lt);
      self.get('i18n').addTranslations('lv', externalTranslations.lv);
      self.get('i18n').addTranslations('nl', externalTranslations.nl);
      self.get('i18n').addTranslations('no', externalTranslations.no);
      self.get('i18n').addTranslations('pl', externalTranslations.pl);
      self.get('i18n').addTranslations('pt', externalTranslations.pt);
      self.get('i18n').addTranslations('ro', externalTranslations.ro);
      self.get('i18n').addTranslations('se', externalTranslations.se);
      self.get('i18n').addTranslations('si', externalTranslations.si);
      self.get('i18n').addTranslations('sk', externalTranslations.sk);
    },

    watchValidations: (function () {

      var self = this;
      if (!self.get('systemInvalid') && !self.get('auflagerInvalid') && !self.get('konstruktionInvalid') && !self.get('materialienInvalid') && !self.get('einwirkungInvalid') && !self.get('schwingungsnachweisInvalid')) {
        self.set('schraubenvorauswahlDisabled', false);
      } else {
        self.set('schraubenvorauswahlDisabled', true);
      }
    }).observes('systemInvalid', 'auflagerInvalid', 'konstruktionInvalid', 'materialienInvalid', 'einwirkungInvalid', 'schwingungsnachweisInvalid'),

    getParameterByName: function getParameterByName(name, url) {

      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    setCountryVars: function setCountryVars() {

      var self = this;

      var si = self.getParameterByName('spracheinput');
      var so = self.getParameterByName('spracheoutput');
      var kl = self.getParameterByName('kennungland');
      var kat = self.getParameterByName('katalog');
      var lc = self.getParameterByName('loadsCode');
      var ln = self.getParameterByName('loadsNA');
      var tc = self.getParameterByName('timberCode');
      var tn = self.getParameterByName('timberNA');

      if (window.location.href.indexOf("timberCode") === -1) {
        self.set('si', "DE");
        self.set('so', "DE");
        self.set('kl', "DE");
        self.set('kat', "DE");
        // self.set('lc', "EC 1 NAD (DE)");
        self.set('lc', "EC1");
        self.set('ln', "DE");
        // self.set('tc', "EC 5 NAD (DE)");
        self.set('tc', "EC5");
        self.set('tn', "DE");
        self.set('bemessungsvorschrift', "EC5 (DE)");
      } else {
        self.set('si', si);
        self.set('so', so);
        self.set('kl', kl);
        self.set('kat', kat);
        self.set('lc', lc);
        self.set('ln', ln);
        self.set('tc', tc);
        self.set('tn', tn);

        if (tc.slice(10, tc.length - 1).length === 2) {
          self.set('bemessungsvorschrift', "EC5 (" + tc.slice(10, tc.length - 1) + ")");
        } else {
          if (tc.slice(10, tc.length - 1) === "D") {
            self.set('bemessungsvorschrift', "EC5 (DE)");
          } else if (tc.slice(10, tc.length - 1) === "A") {
            self.set('bemessungsvorschrift', "EC5 (AT)");
          } else if (tc.slice(10, tc.length - 1) === "I") {
            self.set('bemessungsvorschrift', "EC5 (IT)");
          } else if (tc.slice(10, tc.length - 1) === "F") {
            self.set('bemessungsvorschrift', "EC5 (FR)");
          }
        }
      }
    },

    resetApplication: function resetApplication() {
      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      self.transitionToRoute('projektdaten');
    },

    actions: {

      getErgebnisgrafik: function getErgebnisgrafik() {

        var self = this;

        console.log('Testgrafik');

        var application = self.controllerFor('application');
        var x3d = application.get('model').x3ddefault.objectAt(0);
        var store = application.get('store');
        var arrayHelper = [];

        // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen
        application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
          if (item.get('typ') === 'verbindungsmittel' || item.get('typ') === 'masskette' || item.get('typ') === 'platte') {
            arrayHelper.pushObject(item);
          }
        });
        arrayHelper.forEach(function (item) {
          application.get('model').x3ddefault.objectAt(0).get('bauteile').removeObject(item);
        });

        var x3d = application.get('model').x3ddefault.objectAt(0);

        var server = application.get('server');
        var pfad = application.get('pfad');

        $.ajax({
          url: "m0601.json"
        }).done(function (data) {

          var dataObject = data;

          dataObject.bauteile.forEach(function (item) {

            var newID = item.id;
            if (item.typ === "verbindungsmittel") {
              newID = "schraubeStandard1";
            } else if (item.typ === "platte") {
              if (item.id.substring(0, 13) === 'seitenbauteil' && x3d.get('sFolgeHolzHolz') === false && x3d.get('zweiSchnittig') === false) {
                newID = "metall";
              } else if (item.id.substring(0, 13) === 'mittelbauteil' && x3d.get('sFolgeHolzHolz') === false && x3d.get('zweiSchnittig') === true && x3d.get('zweiSchaerflaechen') === true) {
                newID = "metall";
              } else if (item.id.substring(0, 16) === 'verstaerkunggurt' || item.id.substring(0, 15) === 'zwischenschicht') {
                newID = "verstaerkungBlass1";
              } else if (item.id.substring(0, 21) === 'verstaerkungdiagonale') {
                newID = "verstaerkungWerner1";
              } else {
                newID = "holz";
              }
            }

            if (item.typ === "verbindungsmittel") {

              var bauteil = store.push('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: [item.point],
                translations: ["null"],
                rotations: ["null"],
                appearances: [newID],
                diffusecolors: [newID],
                screwProperties: [item.verbindungsmittel],
                richtungsvektor: [item.richtungsvektor],
                lagewinkel: [item.lagewinkel]
              });

              bauteil.save();
              x3d.get('bauteile').pushObject(bauteil);
            } else if (item.typ === "platte") {

              var bauteil = store.push('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: item.point,
                translations: ["null"],
                rotations: ["null"],
                appearances: [newID],
                diffusecolors: [newID],
                richtungsvektor: [item.richtungsvektor],
                orthovektor: [item.orthovektor],
                platte: [item.id]
              });

              bauteil.save();
              x3d.get('bauteile').pushObject(bauteil);
            } else if (item.typ === "masskette") {

              var bauteil = store.push('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: item.point,
                translations: ["null"],
                rotations: ["null"],
                appearances: [newID],
                diffusecolors: [newID],
                richtungsvektor: [item.richtungsvektor],
                // orthovektor: [item.orthovektor],
                masskette: [item.id],
                ansichtsvektoren: [item.offsetVektorAnsichtXY, item.offsetVektorAnsichtXZ, item.offsetVektorAnsichtYZ]

              });

              bauteil.save();
              x3d.get('bauteile').pushObject(bauteil);
            }
          });

          var xWerte = [];
          var yWerte = [];
          var zWerte = [];

          console.log('punkte');
          if (typeof dataObject.punkte === "undefined") {
            console.log('PUNKTE in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.punkte.forEach(function (item) {

              xWerte.push(Number(item.x));
              yWerte.push(Number(item.y));
              zWerte.push(Number(item.z));

              var p = store.push('punkt', item);
              p.save();
            });

            application.setMinMaxWerte("x", xWerte);
            application.setMinMaxWerte("y", yWerte);
            application.setMinMaxWerte("z", zWerte);
          }

          console.log('platten');
          if (typeof dataObject.platte === "undefined") {
            console.log('PLATTE in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.platte.forEach(function (item) {
              var p = store.push('platte', item);
              p.save();
            });
          }

          console.log('richtungsvektoren');
          if (typeof dataObject.richtungsvektoren === "undefined") {
            console.log('RICHTUNGSVEKTOREN in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.richtungsvektoren.forEach(function (item) {
              var r = store.push('richtungsvektor', item);
              r.save();
            });
          }

          console.log('ansichtsvektoren');
          if (typeof dataObject.ansichtsvektoren === "undefined") {
            console.log('ANSICHTSVEKTOREN in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.ansichtsvektoren.forEach(function (item) {
              var r = store.push('ansichtsvektoren', item);
              r.save();
            });
          }

          console.log('orthovektoren');
          if (typeof dataObject.orthovektoren === "undefined") {
            console.log('ORTHOVEKTOREN in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.orthovektoren.forEach(function (item) {
              var r = store.push('orthovektor', item);
              r.save();
            });
          }

          console.log('screw-properties');
          if (typeof dataObject.verbindungsmittel === "undefined") {
            console.log('VERBINDUNGSMITTEL in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.verbindungsmittel.forEach(function (item) {
              var screwProp = store.push('screw-property', item);
              screwProp.save();
            });
          }

          console.log('massketten');
          if (typeof dataObject.masskette === "undefined") {
            console.log('MASSKETTEN in der geladenen Dateie nicht vorhanden');
          } else {
            x3d.set('ebene', 1);
            dataObject.masskette.forEach(function (item) {
              var massk = store.push('masskette', item);
              var textEbene = x3d.get('ebene');
              massk.save();
            });
          }

          console.log('lagewinkel');
          if (typeof dataObject.lagewinkel === "undefined") {
            console.log('LAGEWINKEL in der geladenen Dateie nicht vorhanden');
          } else {
            dataObject.lagewinkel.forEach(function (item) {
              var lagewinkel = store.push('lagewinkel', item);
              lagewinkel.save();
            });
          }

          x3d.set('ergebnisGeladen', true);
          x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');
          x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
          x3d.set('transformHelper', !x3d.get('transformHelper'));

          console.log('----- getErgebnisgrafik -----');

          self.controllerFor('application').zentriertObjekt();
        });
      },

      setSpracheInput: function setSpracheInput(spracheInput) {
        var self = this;
        this.get('i18n').set('locale', spracheInput.toLowerCase());
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('spracheinput', spracheInput);
        applicationdata.set('spracheoutput', spracheInput);
        self.send('setCountryCode', spracheInput);

        self.set('selectedLanguage', self.get('i18n').t(self.languages[spracheInput]));

        if (spracheInput == "DE") {
          self.set('hilfelink', 'http://holzbauverbinder.de/hilfe/wuerth/html/de/m0601___modul_holz_beton_verbunddecke_.html?ms=AAAA&st=MA%3D%3D&sct=MTc5MQ%3D%3D&mw=MzAw'); // Würth
        } else {
            self.set('hilfelink', 'http://holzbauverbinder.de/hilfe/wuerth/html/en/m0601___wood_concrete_composite.html?ms=AAAA&st=MA%3D%3D&sct=MTcyMQ%3D%3D&mw=MzAw#'); // Würth
          }

        this.controllerFor('impressum').set('selectedLanguage', spracheInput);

        this.controllerFor('konstruktion').setTranslations();

        this.controllerFor('einwirkung').send('setKLED', 1, kledMenuDB[201].kled, kledMenuDB[201].textid, 201);

        this.controllerFor('materialien').setSelectFieldsContent();
        this.controllerFor('schwingungsnachweis').setSelectFieldsContent();

        self.resetApplication();
      },

      // localhost:4200/?user_name=&user_sessionid=xxx&spracheinput=DE&spracheoutput=DE&kennungland=AT&katalog=DE&loadsCode=EC%201%20NAD%20(AT)&loadsNA=AT&timberCode=EC%205%20NAD%20(AT)&timberNA=AT&foo=0.512015296850361

      setCountryCode: function setCountryCode(countryCode) {

        var self = this;

        console.log("setCountryCode: " + countryCode);

        self.set('cC', countryCode === "EN" ? "UK" : countryCode);

        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('kennungland', countryCode === "EN" ? "UK" : countryCode);

        var firm = applicationdata.get('firm').toLowerCase();

        var projektdaten = this.controllerFor('projektdaten');

        var downloadcenter = this.controllerFor('downloadcenter');

        var cC = countryCode.toLowerCase();
        var eta = "120196";

        if (typeof etaConfig[firm] === "undefined") {
          firm = "SWG";
        }

        if (typeof etaConfig[firm][cC] === "undefined") {
          cC = "EN";
        }

        if (firm === "WUERTH") {
          eta = "110190";
        }

        var prodkaturl = "";
        var etaurl = "";

        var katalogID = 0;
        var etaID = 0;

        prodkaturl = etaConfig[firm][cC]["files"]["Catalogs"][katalogID]["pfad"] + etaConfig[firm][cC]["files"]["Catalogs"][katalogID]["dateiname"];
        etaurl = etaConfig[firm][cC]["files"]["ETAs"][etaID]["pfad"] + etaConfig[firm][cC]["files"]["ETAs"][etaID]["dateiname"];

        this.set('eta', etaurl);
        this.set('produktkatalog', prodkaturl);

        self.set('selectedCountry', self.get('i18n').t(self.countries[countryCode === "EN" ? "UK" : countryCode]));

        console.log(applicationdata.get('kennungland'));

        self.resetApplication();
      },

      setETA: function setETA(eta) {
        var applicationdata = this.get('model').application.objectAt(0);

        var etaurl = "assets/public/docs/eta_" + eta.toLowerCase() + ".pdf";

        this.set('eta', etaurl);
      },

      setBemessungsvorschrift: function setBemessungsvorschrift(bemessungsvorschrift) {
        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);

        if (bemessungsvorschrift === "DE" || bemessungsvorschrift === "AT") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else {
          if (bemessungsvorschrift === "DK" || bemessungsvorschrift === "FI") {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
            applicationdata.set('loadsNA', "DE");
            applicationdata.set('timberNA', bemessungsvorschrift);
          } else {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            if (bemessungsvorschrift === "IT") {
              // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
              applicationdata.set('loadsNA', "DE");
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('timberNA', bemessungsvorschrift);
            } else if (bemessungsvorschrift === "FR") {
              // applicationdata.set('loadsCode', "EC 1 NAD ("+ bemessungsvorschrift +")");
              applicationdata.set('loadsNA', bemessungsvorschrift);
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('timberNA', bemessungsvorschrift);
            }
          }
        }

        applicationdata.set('loadsCode', "EC1");
        applicationdata.set('timberCode', "EC5");

        this.set('bemessungsvorschrift', "EC5 (" + bemessungsvorschrift + ")");

        self.resetApplication();
      },

      downloadcenter: function downloadcenter(spracheInput) {
        this.transitionToRoute('downloadcenter');
      },

      verbindungsmittel: function verbindungsmittel(event) {
        console.log('test');
        var nachweise = this.controllerFor('schwingungsnachweis');
        var applicationdata = this.get('model').application.objectAt(0);

        if (!applicationdata.get('treeLoaded')) {
          console.log("jetzt müsste der Tree neu geladen werden.");
          nachweise.send('proceedInput');
        } else {
          console.log("jetzt müsste der Tree NICHT neu geladen werden.");
        }
      },

      ergebnis: function ergebnis(event) {
        console.log('test');
        var verbindungsmittel = this.controllerFor('verbindungsmittel');
        var ergebnis = this.controllerFor('ergebnis');

        verbindungsmittel.send('proceedInput');
        ergebnis.send('returnUser');
      },

      daemmungladen: function daemmungladen(event) {
        var daemmung = this.controllerFor('daemmung');

        daemmung.send('getDaemmstoffbaum');
      },

      // http://m01wuerth.holzbauverbinder.de/?user_name=wuerth_intern&user_sessionid=0.0574437920822

      newAction: function newAction(event) {
        console.log(window.location.href);

        var self = this;

        var protocol = window.location.protocol;
        var host = window.location.host;

        var applicationdata = this.get('model').application.objectAt(0);
        var si = applicationdata.get('spracheinput');
        var so = applicationdata.get('spracheoutput');
        var kl = applicationdata.get('kennungland');
        var kat = applicationdata.get('katalog');
        var lc = applicationdata.get('loadsCode');
        var ln = applicationdata.get('loadsNA');
        var tc = applicationdata.get('timberCode');
        var tn = applicationdata.get('timberNA');
        var bv = self.get('bemessungsvorschrift');

        window.location.href = protocol + "//" + host + "/?user_name=" + self.get('username') + "&user_sessionid=" + self.get('sessionid') + "&spracheinput=" + si + "&spracheoutput=" + so + "&kennungland=" + kl + "&katalog=" + kat + "&loadsCode=" + lc + "&loadsNA=" + ln + "&timberCode=" + tc + "&timberNA=" + tn + "&bemessungsvorschrift=" + bv;
      },

      contactAction: function contactAction(event) {

        tools.validationsToTooltips();

        var self = this;

        self.transitionToRoute('contact');
      },

      produktkatalogAnzeigen: function produktkatalogAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      etaAnzeigen: function etaAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      setCameraActiv: function setCameraActiv(camera) {
        var lastCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', this.get('model').x3ddefault.objectAt(0).get('lastViewpoint'));

        this.get('model').x3ddefault.objectAt(0).set('lastViewpoint', camera);

        var activeCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', camera);

        lastCamera.get('cameraProperties').objectAt(0).set('setBind', false);
        activeCamera.get('cameraProperties').objectAt(0).set('setBind', true);

        // resetViewpoint() zu finden in /bower_components/viewpointmanagement/vpmanagements.js
        // setzt die viewpoints zurück

        console.log('----- setCameraActiv -----');

        this.zentriertObjekt();
        this.get('model').x3ddefault.objectAt(0).set('viewpointHelper', !this.get('model').x3ddefault.objectAt(0).get('viewpointHelper'));
      },

      setDrahtgittermodel: function setDrahtgittermodel() {
        this.get('model').x3ddefault.objectAt(0).set('istGitterModell', !this.get('model').x3ddefault.objectAt(0).get('istGitterModell'));
      },

      saveAction: function saveAction() {

        console.log('speichern()');

        var self = this;

        self.transitionToRoute('downloadcenter');
        var dc = self.controllerFor('downloadcenter');
        dc.set('savingInProgress', true);

        var application = self.controllerFor('application');

        //universal
        var applicationdata = application.get('model').application.objectAt(0);
        var applicationdataJSON = JSON.stringify(applicationdata);

        var projektdatendata = application.get('model').projektdaten.objectAt(0);
        var projektdatendataJSON = JSON.stringify(projektdatendata);

        //m0601

        var systemdata = application.get('model').system.objectAt(0);
        var systemdataJSON = JSON.stringify(systemdata);

        var auflagerdata = application.get('model').auflager.objectAt(0);
        var auflagerdataJSON = JSON.stringify(auflagerdata);

        var konstruktiondata = application.get('model').konstruktion.objectAt(0);
        var konstruktiondataJSON = JSON.stringify(konstruktiondata);

        var materialiendata = application.get('model').materialien.objectAt(0);
        var materialiendataJSON = JSON.stringify(materialiendata);

        var einwirkungdata = application.get('model').einwirkung.objectAt(0);
        var einwirkungdataJSON = JSON.stringify(einwirkungdata);

        var nachweisedata = application.get('model').schwingungsnachweis.objectAt(0);
        var nachweisedataJSON = JSON.stringify(nachweisedata);

        var optionendata = application.get('model').optionen.objectAt(0);

        var schraubenvorauswahldata = application.get('model').verbindungsmittel.objectAt(0);
        var schraubenvorauswahldataJSON = JSON.stringify(schraubenvorauswahldata);

        var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(systemdataJSON), JSON.parse(auflagerdataJSON), JSON.parse(konstruktiondataJSON), JSON.parse(materialiendataJSON), JSON.parse(einwirkungdataJSON), JSON.parse(nachweisedataJSON), JSON.parse(schraubenvorauswahldataJSON));

        //Lastannahmen/Parameter_Strukt.vb

        var timberelement = {
          "Querschnittswerte": {
            "b": materialiendata.get('b'),
            "h": materialiendata.get('h'),
            "Predrilled": false,
            "QuerschnittsID": "1"
          }

        };

        var interlayerelement = {
          "Querschnittswerte": {
            "b": materialiendata.get('e_Beam'),
            "h": materialiendata.get('schalung'),
            "Predrilled": false,
            "QuerschnittsID": "1"
          }
        };

        var concreteelement = {
          "Querschnittswerte": {
            "b": materialiendata.get('e_Beam'),
            "h": materialiendata.get('betonDicke'),
            "QuerschnittsID": "1"
          }
        };

        var gebrauchstauglichkeit = {
          "fMin": nachweisedata.get('fMin')
        };

        var vmAnordnung = {
          n90min: optionendata.get('n90min'),
          n90max: optionendata.get('n90max'),
          anzahlVmNebeneinanderGerade: optionendata.get('anzahlVmNebeneinanderGerade'),
          mindestanzahlSchubverbinderLinks: optionendata.get('mindestanzahlSchubverbinderLinks'),
          mindestanzahlSchubverbinderRechts: optionendata.get('mindestanzahlSchubverbinderRechts')
        };

        var einwirkungen = [];

        uebergabedaten.TimberElement = timberelement;
        uebergabedaten.InterlayerElement = interlayerelement;
        uebergabedaten.ConcreteElement = concreteelement;
        uebergabedaten.gebrauchstauglichkeit = gebrauchstauglichkeit;
        uebergabedaten.vmAnordnung = vmAnordnung;

        console.log(JSON.stringify(uebergabedaten));

        var anzahlLasten = application.get('model').einzelneeinwirkung.content.length;

        console.log("######");
        console.log(anzahlLasten);

        for (var i = 0; i <= anzahlLasten - 1; i++) {
          var last = {
            'LoadProperties': application.get('model').einzelneeinwirkung.objectAt(i),
            'LoadEffect': {
              'MyID': 0,
              'MyText': 'foo',
              'MyKLED': 2,
              'MyTextKLED': 'feucht',
              'MyXi0': 1.0,
              'MyXi1': 2.0,
              'MyXi2': 3.0,
              'MyOriginID': 23
            }
          };
          einwirkungen.push(last);
        }

        console.log("!!!");
        console.log(einwirkungen);

        uebergabedaten.LoadList = einwirkungen;

        var JSONdata = JSON.stringify(uebergabedaten);

        var initialized = applicationdata.get('initialized');

        var parasToSave = JSON.parse(JSONdata);

        var dataToSave = JSON.stringify(auxiliary.save([applicationdata.data, projektdatendata.data, parasToSave]));

        //var JSONdata = "{" + dachgeometriedataJSONcut + "," + unterkonstruktiondataJSONcut + "," + daemmungdataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
        //var JSONdata = "{" + bauteileString + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";

        // var JSONdata = "{" + projektdatendataJSONcut + "," + bauteiledataJSONcut + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
        if (self.debug) {
          console.log(JSONdata);
        }

        var initialized = applicationdata.get('initialized');

        // server + "m08/DE/initialisiereBerechnung/",
        // server + "speichereBerechnung/" + cid + "/"

        var server = self.get('server');
        var pfad = self.get('pfad');

        if (!initialized) {

          $.ajax({
            type: "POST",
            url: server + "initialisiereBerechnung/",
            //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
            data: {
              kennung: applicationdataJSON,
              // projektdaten: projektdatendataJSON,
              paras: JSONdata,
              modul: "m0601",
              pfad: pfad
            }
          }).done(function (data, statusText, xhr) {

            self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

            var cid = data;
            console.log('cid: ' + cid);
            applicationdata.set('Calculation_ID', cid);
            applicationdata.set('initialized', true);
            dc.set('cid', data);

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          });
        } else {

            var cid = applicationdata.get('Calculation_ID');

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          }
        self.set('unsaved', false);
      },

      /**
       * This action is called when a file has been loaded.
       *
       * @method ACTION: fileLoaded
       */
      fileLoaded: function fileLoaded(file) {

        var self = this;

        if (self.xml) {
          var test = self.parseXml(file.data);
          console.log('test');
          console.log(xml2json(test, ""));
        } else {
          var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);

          var projektdaten = self.controllerFor('projektdaten');
          var system = self.controllerFor('system');
          var auflager = self.controllerFor('auflager');
          var konstruktion = self.controllerFor('konstruktion');
          var materialien = self.controllerFor('materialien');
          var nachweise = self.controllerFor('schwingungsnachweis');
          var einwirkung = self.controllerFor('einwirkung');
          var optionen = self.controllerFor('optionen');
          // var schraubenvorauswahl = self.controllerFor('schraubenvorauswahl');

          var values = JSON.parse(file.data);

          self.send('setSpracheInput', values.spracheinput);

          applicationdata.set('katalog', values.katalog);

          self.send('setBemessungsvorschrift', values.timberNA);

          applicationdata.set('kennungland', values.kennungland);
          applicationdata.set('userID', values.userID);
          applicationdata.set('userName', values.userName);

          projektdaten.setValues(JSON.parse(file.data));
          system.setValues(JSON.parse(file.data));
          auflager.setValues(JSON.parse(file.data));
          konstruktion.setValues(JSON.parse(file.data));
          einwirkung.setValues(JSON.parse(file.data));
          materialien.setValues(JSON.parse(file.data));
          nachweise.setValues(JSON.parse(file.data));
          optionen.setValues(JSON.parse(file.data));
          // schraubenvorauswahl.setValues(JSON.parse(file.data));

          console.log('initialized: ' + self.get('model').application.objectAt(0).get('initialized'));

          self.controllerFor('application').get('model').application.objectAt(0).set('initialized', true);

          console.log('initialized: ' + self.get('model').application.objectAt(0).get('initialized'));
        }
      },

      toggleForm: function toggleForm() {
        $('.clearfix').toggleClass('formZu');
        $('#formToggler').toggleClass('open');

        setTimeout(function () {
          var x3dGrafik = document.getElementById("threedee");
          var x3d = document.getElementById("x3d");
          var canvas = document.getElementsByTagName("canvas");
          var x3druntime = document.getElementById('x3d').runtime;

          // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
          x3d.setAttribute('height', Number($(window).height()) - 300);
        }, 400);
      }
    },

    miniertX3D: function miniertX3D() {
      $('.clearfix').removeClass('formZu');
      $('#formToggler').removeClass('open');

      setTimeout(function () {
        var x3dGrafik = document.getElementById("threedee");
        var x3d = document.getElementById("x3d");
        var canvas = document.getElementsByTagName("canvas");
        var x3druntime = document.getElementById('x3d').runtime;

        // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
        x3d.setAttribute('height', Number($(window).height()) - 300);
      }, 400);
    },

    parseXml: function parseXml(xml) {

      var dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, "text/xml");
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new ActiveXObject('Microsoft.XMLDOM');
          dom.async = false;
          if (!dom.loadXML(xml)) // parse error ..

            window.alert(dom.parseError.reason + dom.parseError.srcText);
        } catch (e) {
          dom = null;
        }
      } else alert("cannot parse xml string!");
      return dom;
    },

    x3dTranslation: (function () {
      return '0 0 0';
    }).property('model.x3ddefault.firstObject.istDachkonstruktion', 'model.x3ddefault.firstObject.vWinkel'),

    x3dRotation: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var rotation;
      if (this.get('model').x3ddefault.objectAt(0).get('istDachkonstruktion')) {
        rotation = '0 0 1 ' + x3d.get('vWinkel') * Math.PI / 180;
      } else {
        rotation = '0 0 0 0';
      }
      return rotation;
    }).property('model.x3ddefault.firstObject.istDachkonstruktion', 'model.x3ddefault.firstObject.vWinkel'),

    schalungAnzeigen: (function () {
      var value = false;
      if (Number(this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y')) > 0) {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    ftVerbinderAnzeige: (function () {
      var value = false;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      // if (x3d.get('ergebnisGeladen') !== true && (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftTrocken') || (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftNass')) {
      if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftTrocken' || bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('hbvSystem') === 'ftNass') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    aufgehendesbauteil1: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil1');
    }).property('model.x3ddefault.firstObject.transformHelper'),

    aufgehendesbauteil2: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil2');
    }).property('model.x3ddefault.firstObject.transformHelper'),

    tiefeKonstruktion: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var deckenkonstruktion = x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion');
      var value = false;
      if (deckenkonstruktion === 'balken') {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    randAbstandLinks: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return !x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager1Durchlaufend');
    }).property('model.x3ddefault.firstObject.transformHelper'),

    randAbstandRechts: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      return !x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('auflager2Durchlaufend');
    }).property('model.x3ddefault.firstObject.transformHelper'),

    lasten: (function () {
      var self = this;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var lastenListe = Ember['default'].Set.create();

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'last' && (item.get('lastProperties').objectAt(0).get('gespeichert') === true || item.get('lastProperties').objectAt(0).get('editieren') === true) && Number(item.get('boxsizes').objectAt(0).get('y')) !== 0) {
          lastenListe.add(item);
          if (x3d.get('systemwerteGeaendert') === true) {
            // console.log('last.id: '+item.id);
            self.setLastGrafik(item.id);
          }
        }
      });

      x3d.set('systemwerteGeaendert', false);

      return lastenListe.toArray();
    }).property('model.x3ddefault.firstObject.lastenTrigger'),

    lastenYTranslation: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hoehe = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y') / 10 + 0.5;

      return '0 ' + hoehe + ' 0';
    }).property('model.x3ddefault.firstObject.lastenTrigger'),

    setLastGrafik: function setLastGrafik(lastBez) {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var einzelneeinwirkung = application.get('model').einzelneeinwirkung;
      var bauteile = x3d.get('bauteile');

      var lastart = bauteile.findBy('id', lastBez).get('lastProperties').objectAt(0).get('lastart');
      var newTranslation = 0;
      var xl = 0;

      // console.log('einzelneeinwirkung');
      // console.log(application.get('model').einzelneeinwirkung);
      // console.log(application.get('model').einzelneeinwirkung.objectAt(0));
      // console.log(application.get('model').einzelneeinwirkung.objectAt(2));
      // console.log(application.get('model').einzelneeinwirkung.findBy('id', 0));
      // console.log(application.get('model').einzelneeinwirkung.findBy('id', "0"));
      // console.log(application.get('model').einzelneeinwirkung.findBy('id', 2));
      // console.log(application.get('model').einzelneeinwirkung.findBy('id', "2"));
      // console.log('lastID: '+lastBez);
      // console.log('lastart: '+lastart);
      // console.log(lastBez.substring(4));

      if (lastart === 'einzellast') {
        var xSize = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1')) / 10;
        xl = Number(einzelneeinwirkung.findBy('id', lastBez.substring(4)).get('x_li'));
        newTranslation = -xSize / 2 + xl * 10;
        bauteile.findBy('id', lastBez).get('translations').objectAt(0).set('x', newTranslation);
      } else if (lastart === 'gleichlast') {
        bauteile.findBy('id', lastBez).get('translations').objectAt(0).set('x', 0);
      } else {
        var xSize = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1') / 10);
        xl = Number(einzelneeinwirkung.findBy('id', lastBez.substring(4)).get('x_li'));
        var xr = Number(einzelneeinwirkung.findBy('id', lastBez.substring(4)).get('x_re'));

        xSize = xSize - xl * 10 - xr * 10;

        newTranslation = (xl - xr) / 2;
        bauteile.findBy('id', lastBez).get('boxsizes').objectAt(0).set('x', xSize * 10);
        bauteile.findBy('id', lastBez).get('translations').objectAt(0).set('x', newTranslation * 10);
      }
    },

    zentriertObjekt: function zentriertObjekt() {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var ueberstand = 2;

      // var x = (Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x')) + Number(x3d.get('laengeFehlstelle')) + Number(x3d.get('ueberkopplungslaenge')) + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x'))) / 10 + 1.2 * skalierungsfaktor;
      var x = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('laengeF1')) / 10 + 2;
      var y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) / 10 + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('y')) / 10 + Number(bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).get('y')) / 10 + Number(bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).get('y')) / 10;

      if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil1') === true || bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil2') === true || bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil3') === true || bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil4') === true || bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('aufgehendeBauteil5') === true) {
        y = y + Number(bauteile.findBy('id', 'aufgehendesbauteil1').get('boxsizes').objectAt(0).get('y')) / 10;
      }

      var z = Number(bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('tiefeUnterkonstruktion')) / 10 + 0.6 * skalierungsfaktor;

      var arr = [x, y, z];
      arr.sort(function (a, b) {
        return b - a;
      });

      var d = 0;
      var val = Number(arr[0]);

      if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
        d = Math.sqrt(Math.pow(val, 2) + Math.pow(val * 0.4, 2) + Math.pow(val, 2));
      } else {
        d = arr[0];
      }

      x3d.set('aktuelleDistance', d);

      resetViewpoint(d);

      document.getElementById('navInfo').setAttribute('speed', '0.5');
    },

    ergebnisGeladen: (function () {

      console.log('ERGEBNIS GELADEN');

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = x3d.get('ergebnisGeladen');
      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen'),

    abstaendeAnzeigen: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = x3d.get('abstaendeAnzeigen');
      return value;
    }).property('model.x3ddefault.firstObject.abstaendeAnzeigen'),

    ergebnisMassketten: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var masskettenArray = Ember['default'].Set.create();

      if (x3d.get('ergebnisGeladen') === true) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "masskette" && item.get('masskette').objectAt(0).get('masstext') !== "0.00" && item.get('masskette').objectAt(0).get('masstext') !== "0.0") {
            masskettenArray.add(item);
          }
        });
      }

      return masskettenArray.toArray();
    }).property('model.x3ddefault.firstObject.schraubenlisteAktualisiert', 'model.x3ddefault.firstObject.viewpointHelper', 'model.x3ddefault.firstObject.lastViewpoint'),

    plattenListe: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var platesArray = Ember['default'].Set.create();

      bauteile.forEach(function (item) {
        if (item.get('typ') === "platte") {
          platesArray.add(item);
        }
      });

      return platesArray.toArray();
    }).property('model.x3ddefault.firstObject.transformHelper'),

    eingabenUngueltig: (function () {
      var self = this;
      var value = true;

      if (self.get('projektdatenInvalid') === false && self.get('systemInvalid') === false && self.get('auflagerInvalid') === false && self.get('konstruktionInvalid') === false && self.get('materialienInvalid') === false && self.get('einwirkungInvalid') === false && self.get('schwingungsnachweisInvalid') === false && self.get('optionenInvalid') === false) {
        value = false;
      }

      return value;
    }).property('projektdatenInvalid', 'systemInvalid', 'auflagerInvalid', 'konstruktionInvalid', 'materialienInvalid', 'einwirkungInvalid', 'schwingungsnachweisInvalid', 'optionenInvalid'),

    setMinMaxWerte: function setMinMaxWerte(bezeichner, array) {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var dimensionen = x3d.get('dimensionen');

      array.sort(function (a, b) {
        return a - b;
      });

      dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').set(bezeichner, Number(array[0]));
      dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').set(bezeichner, Number(array[array.length - 1]));
    },

    arrayAbsteigendSortieren: function arrayAbsteigendSortieren(arr) {
      var ergebnis = arr.sort(function (a, b) {
        return b - a;
      });

      return ergebnis;
    },

    getAbstandPunkte: function getAbstandPunkte(x1, x2) {
      return Math.abs(x1 - x2);
    },

    getSumme: function getSumme(x1, x2) {
      return Math.abs(x1 + x2);
    },

    dippelbaum: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).get('deckenkonstruktion') === 'dippelbaum') {
        return true;
      }

      return false;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    etaListe: (function () {
      var self = this;

      var firm = self.get('model').application.objectAt(0).get('firm').toLowerCase();
      var cC = self.get('model').application.objectAt(0).get('kennungland').toLowerCase();
      var liste = Ember['default'].Set.create();

      if (cC === "uk") {
        cC = "en";
      }

      Object.values(etaConfig[firm][cC]["files"]["ETAs"]).forEach(function (item, i) {
        var eintrag = { name: item.name, beschreibung: item.beschreibung, link: "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname, m0601: item.m0601 };
        liste.add(eintrag);
      });

      return liste.toArray();
    }).property('cC'),

    urlProduktkatalog: (function () {
      var self = this;

      var firm = self.get('model').application.objectAt(0).get('firm').toLowerCase();
      var cC = self.get('model').application.objectAt(0).get('kennungland').toLowerCase();

      if (cC === "uk") {
        cC = "en";
      }

      var item = etaConfig[firm][cC]["files"]["Catalogs"]["0"];

      var downloadcenter = this.controllerFor('downloadcenter');
      downloadcenter.set('produktkatalog', "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname);
      downloadcenter.set('katalogFehlt', false);

      return "https://timberconnect.de" + item.pfad.replace("..", "") + item.dateiname;
    }).property('cC')

  });

});